import { useEffect, useState } from 'react';
import Home from './Home/home'
import Survey from './Survey/survey'
import Result from './Result/result'
import * as API from '../services/users'
function Main()
{    
    const [user,setUser] = useState();
    useEffect(()=>{
        API.GetUser().then((res)=>{
        
        if(res.data.user_id)
        {
            sessionStorage.setItem("name",res.data.firstname);
            setUser(res.data);
        }
        else
            window.location.href="/auth"   
        }).catch((err)=>{
          
        })
    },[])

    if(!user)
        return <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <span className='loader'></span>
        </div>
    
    if(user.completed_date!=null)
        return <Result />
    
    if(user.start_date!=null)
        return <Survey oncomplete={()=>{ 
            setUser({...user,completed_date:1}) 
        }} />
    
    return <Home onStart={()=>{
        setUser({...user,start_date:1})
    }} />
}

export default Main;