import './question.scss'

function question({question,value,onchange,number})
{
    let options = [{text:"Strongly Agree",value:5},{text:"Agree",value:4},{text:"Neutral",value:3},{text:"Disagree",value:2},{text:"Strongly Disagree",value:1}]
    return <>
        <div style={{marginBottom:48}}>
            <div>
                <div style={{display:'flex',gap:10}}>
                    <span className='questiontext'>{number}. </span>
                    <span className='questiontext'>{question}</span>    
                </div>
                
                <div className='optioncontainer'>
                    {options.map((d)=><div className='optionblock' onClick={()=>{
                        onchange(d.value);
                    }}>
                        <div>
                            <span className={`checkmark ${value===d.value?'checkmarkchecked':''}`}>
                                <span />
                            </span>
                        </div>
                        <div style={{textAlign:'center'}}>
                            {d.text}
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </>
}

export default question