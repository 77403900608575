import './result.scss'
import Header from "../Layout/header";
import Hero from "../components/Hero/hero";
import { useEffect, useState } from 'react';
import * as API from '../../services/users'
import _ from 'lodash'
import contents from './content.json'
import Footer from '../Layout/footer';
import Button from '../components/Button';
import jsPDF  from 'jspdf';
let scoreIndex = [0,1,1,3,5,5];
let colors = ["#EE6C4B","#F4A337","#F9D924","#AAC848","#59B66D"]
function Result()
{
    const [result,setResult] = useState(undefined);
    useEffect(()=>{
        API.GetResult().then((res)=>{
            setResult(res.data)
        }).catch((err)=>{
            
        })
    },[])
    
    if(!result)
        return <></>;
    let scrore = Math.round(_.sum(result.map((d)=>Number(d.score.toString())))/result.length);

    const SavePDF = () => {
        var doc = new jsPDF('p', 'mm', 'a4');
        let width = 210;
        let currentPos = 20;
        doc.setTextColor('rgb(0, 0, 0)');
        doc.setFontSize(16)
        doc.text(`${sessionStorage.getItem("name")}'s Assessment Result`.toUpperCase(),width/2,currentPos,{align:'center'});

        
        
        
        currentPos = currentPos + 15;
        doc.setFillColor(colors[scrore-1]);
        doc.roundedRect((width/2)+31,currentPos-6,10,8,1,1,"F");
        

        
       // doc.setTextColor('rgb(255, 255, 255)');
        doc.setFontSize(16)
        doc.text(`${scrore}`,width/2+36,currentPos,{align:'center'});

        doc.setTextColor('rgb(0, 0, 0)');
        doc.setFontSize(16)
        doc.text(`Assessment Score =     `.toUpperCase(),width/2,currentPos,{align:'center'});


        currentPos = currentPos + 15;
        

        doc.setTextColor('rgb(35, 35, 35)');
        doc.setFontSize(12)
        doc.text(`Chaos`,70,currentPos);

        
        doc.setFontSize(12)
        doc.text(`Predictable`,width-10,currentPos,{align:'right'});
        
        currentPos = currentPos-1.4;
        doc.setDrawColor(204, 204, 204);
        doc.setFillColor("rgb(204, 204, 204)");
        
        doc.line(84,currentPos,width-35,currentPos);
        doc.line(width-36.5,currentPos-1.5,width-35,currentPos);
        doc.line(width-36.5,currentPos+1.5,width-35,currentPos);

        doc.circle(84,currentPos,0.5,'F');

        currentPos = currentPos+5;
        doc.setFillColor("#FFEEC6");
        let boxWidth = (width-80)/5;
        let i =0;
        for(i=0;i<5;i++)
        {
            doc.setFillColor(colors[i]);
            
            doc.rect(70+boxWidth*i,currentPos,boxWidth,8,"F");
            doc.line(70+boxWidth*i,currentPos,70+boxWidth*i,currentPos+8);
            doc.text((i+1).toString(),70+boxWidth*i+(boxWidth/2),currentPos+6,{align:'center'})
        }
        currentPos = currentPos+8;
        doc.line(10,currentPos,width-10,currentPos);
        
        result.forEach((d)=>{
            
            for(i=0;i<5;i++)
            {
                
                if(d.score.toString()===(i+1).toString())
                {
                    doc.setFillColor(colors[i]);
                    doc.rect(70+boxWidth*i,currentPos,boxWidth,8,"F");
                }
                doc.line(70+boxWidth*i,currentPos,70+boxWidth*i,currentPos+8);
                doc.setFontSize(10);
                doc.text(d.category_name,68,currentPos+5.5,{align:'right'});
            }
            currentPos = currentPos+8;
            doc.line(10,currentPos,width-10,currentPos);
        })

        currentPos = currentPos+15;
        doc.setTextColor('rgb(0, 0, 0)');
        doc.setFontSize(16)
        doc.text(`CATEGORY DETAIL`.toUpperCase(),width/2,currentPos,{align:'center'});

        currentPos = currentPos + 10;
        
        result.forEach((d)=>{
            doc.setFontSize(13)    
            doc.text(d.category_name.toUpperCase(),10,currentPos);
            currentPos = currentPos+4;
            let boxWidth=16;
            for(i=0;i<5;i++)
            {
                
                doc.setFillColor(d.score.toString()===(i+1).toString()?colors[i]:"#F1F1F1");        
                doc.rect(10+boxWidth*i,currentPos,boxWidth,6,"F");
                doc.setFontSize(10)
                doc.text((i+1).toString(),10+boxWidth*i+(boxWidth/2),currentPos+4.5,{align:'center'})
            }
            
            currentPos = currentPos+14;
            let text = contents.categories[Number(d.category_id.toString())].values[scoreIndex[Number(d.score.toString())]];
            doc.text(text,10,currentPos,{maxWidth:width-20});
            currentPos = currentPos+((doc.getTextWidth(text)/(width-20))*6);
            currentPos = currentPos + 8;
            if(currentPos+20+10>297)
            {
                doc.addPage();
                currentPos=20;
            }
        })

        currentPos=currentPos+10;
        doc.addImage('/img/logo_dark.png',width/2-27.4,currentPos,54.8,14.5);

        doc.save("ASSESSMENT");
    }

    return <>
        <Header />
        <Hero>
            <div className='d-flex align-items-center justify-content-center' style={{flexDirection:'column',gap:20}}>
                <h1 style={{textTransform:'uppercase'}} className='text-center'>{sessionStorage.getItem("name")}'s Assessment Result</h1>
                <Button text={"DOWNLOAD"} secondary={true} size='large' onClick={()=>{
                    SavePDF();
                }} />
            </div>
        </Hero> 
        
        
            
            <div className="container layoutmargin">
                <h2 className="text-center mb-5">
                    Assessment Score = <span style={{backgroundColor:colors[scrore-1],paddingLeft:16,paddingRight:16,borderRadius:6}}>{scrore}</span>
                </h2>
                    <div>
                        <div className='mb-4'>
                            <div className='d-flex'>
                                <div className='shadebox_text' style={{borderLeft:0,borderBottom:0}}>

                                </div>
                                <div style={{color:'#212123',flex:1}} className='d-flex align-items-center'>
                                    <div style={{fontWeight:600}} className='graph_title'>Chaos</div>
                                    <div style={{height:7,width:7,backgroundColor:'#ccc',borderRadius:4,marginLeft:8}}></div>
                                    <div style={{flex:1,height:1,backgroundColor:'#ccc'}}></div>
                                    <div style={{marginLeft:-10,marginRight:8}}>
                                        <div style={{borderTop:'2px solid #ccc',borderRight:'2px solid #ccc',height:8,width:8,transform:'rotate(45deg)'}}></div>
                                    </div>
                                    <div style={{fontWeight:600}} className='graph_title'>Predictable</div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="shadebox_text"></div>
                                <div className="shadebox shade1">1</div>
                                <div className="shadebox shade2">2</div>
                                <div className="shadebox shade3">3</div>
                                <div className="shadebox shade4">4</div>
                                <div className="shadebox shade5">5</div>
                            </div>
                            {result.map((d)=><div className="d-flex">
                                <div className="shadebox_text">{d.category_name}</div>
                                <div className={`shadebox ${d.score.toString()==='1'?'shade1':''}`}></div>
                                <div className={`shadebox ${d.score.toString()==='2'?'shade2':''}`}></div>
                                <div className={`shadebox ${d.score.toString()==='3'?'shade3':''}`}></div>
                                <div className={`shadebox ${d.score.toString()==='4'?'shade4':''}`}></div>
                                <div className={`shadebox ${d.score.toString()==='5'?'shade5':''}`}></div>
                            </div>)}
                            
                        </div>
                    </div>
                </div>    
                <div>
            <div style={{backgroundColor:'#F5F5F5'}} className="p-3 py-5 d-flex justify-content-center">
                <div className='schedule_container'>
                    <div className='schedule_container_img'>
                        <img src="/img/Joel_Trammell.png" alt="Joel Trammell" style={{borderRadius:12}} height={190} />
                    </div>
                    <div style={{flex:1}}>
                        <div style={{fontSize:16,lineHeight:'19px'}} className="mb-4">
                            <h3>Let's discuss your results!</h3>
                            Book your free confidential advisory session with CEO-S architect Joel Trammell. <br /><br />
                            We’ll discuss your strengths, any issues you’re currently facing, and strategies for helping your organization deliver predictable performance.
                        </div>
                        <a target='_blank' rel="noreferrer" href={`https://calendly.com/joel-ceos/30min?month=${(new Date()).getFullYear()}-${((new Date()).getMonth()+1)<10?'0':''}${(new Date()).getMonth()+1}`} className="btn schedule_btn" style={{color:'white',display:'block',backgroundColor:'black',textDecoration:'none',fontSize:16,fontWeight:600,height:48}}>
                            SCHEDULE CALL
                        </a>
                    </div>
                </div>
            </div>
        
                <div className='container'>
                <div className='my-5'>
                    <h2 className='text-center'>CATEGORY DETAIL</h2>
                </div>
                {result.map((d)=><div className='my-5'>
                    <h3>{d.category_name}</h3>
                    <div className='d-flex'>
                        <div className={`shadenumber ${d.score.toString()==='1'?'shade1':''}`}>1</div>
                        <div className={`shadenumber ${d.score.toString()==='2'?'shade2':''}`}>2</div>
                        <div className={`shadenumber ${d.score.toString()==='3'?'shade3':''}`}>3</div>
                        <div className={`shadenumber ${d.score.toString()==='4'?'shade4':''}`}>4</div>
                        <div className={`shadenumber ${d.score.toString()==='5'?'shade5':''}`}>5</div>
                    </div>
                    <div className='mt-3'>
                        {contents.categories[Number(d.category_id.toString())].values[scoreIndex[Number(d.score.toString())]]}
                        
                    </div>
                </div>)}
                

            </div>
            
        </div>
        <div>
            <div style={{backgroundColor:'#F5F5F5'}} className="p-3 py-5 d-flex justify-content-center">
                <div className='schedule_container'>
                    <div className='schedule_container_img'>
                        <img src="/img/Joel_Trammell.png" alt="Joel Trammell" style={{borderRadius:12}} height={190} />
                    </div>
                    <div style={{flex:1}}>
                        <div style={{fontSize:16,lineHeight:'19px'}} className="mb-4">
                            <h3>Let's discuss your results!</h3>
                            Book your free confidential advisory session with CEO-S architect Joel Trammell.<br /><br />
                            We’ll discuss your strengths, any issues you’re currently facing, and strategies for helping your organization deliver predictable performance.
                        </div>
                        <a target='_blank' rel="noreferrer" href={`https://calendly.com/joel-ceos/30min?month=${(new Date()).getFullYear()}-${((new Date()).getMonth()+1)<10?'0':''}${(new Date()).getMonth()+1}`} className="btn schedule_btn" style={{color:'white',display:'block',backgroundColor:'black',textDecoration:'none',fontSize:16,fontWeight:600,height:48}}>
                            SCHEDULE CALL
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <Footer noMargin={true} />
    </>
}

export default Result;