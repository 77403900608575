import './header.scss'

function Header()
{
    return <>
        <div className='header'>
            <div className='d-flex align-items-center' style={{flex:1}}>
                <div style={{flex:1}} className='d-flex justify-content-center'>
                    <a href='https://ceosys.co/'  target='_blank' rel="noreferrer"><img src='/logo.png' alt='CEO Logo' className='logo' /></a>
                </div>
            </div>
        </div>
    </>
}

export default Header;