import './hero.scss'

function Hero({children})
{
    return <div className='hero'>
        <div className='mx-3 d-flex justify-content-center align-items-center' style={{width:'100%',flexDirection:'column'}}>
            {children}
        </div>
    </div>
}
export default Hero