import Header from "../Layout/header";
import Hero from "../components/Hero/hero";
import Question from "./components/Question";
import ProgressBar from './components/progressbar'
import { useEffect, useState } from "react";
import * as API from '../../services/users'
import Button from "../components/Button";
import _ from 'lodash'
import Footer from "../Layout/footer";
let cnt=0;


function Survey({oncomplete})
{
    const [questions,setQuestions] = useState(undefined);
    const [min,setMin] = useState(0);
    const [max,setMax] = useState(0);
    const [reload,setReload] = useState(0);
    const [saving,setSaving] = useState(false);
    const [generating,setGenerating] = useState(false);
    const GetQuestions = () =>{
        setQuestions(undefined);
        API.GetQuestions().then((res)=>{
            cnt = res.data.cnt;
            setQuestions(res.data.data);
            setMin(res.data.min);
            setMax(res.data.max);
        }).catch((err)=>{
            
        })
    }
    useEffect(()=>{
        GetQuestions();
    },[])
    useEffect(()=>{
        if(min && min===max)
        {
            setGenerating(true);
            API.GenerateReport().then((res)=>{
                oncomplete();
                setGenerating(false);
            }).catch((err)=>{

            })
        }
    },[min,max,oncomplete])
    return <>
        <Header />
        <Hero>
            <div style={{maxWidth:600,width:'100%'}}>
                <h1 className="text-center">SURVEY</h1>
            </div>
            <div style={{maxWidth:600,padding:10,width:'100%'}}>
                <ProgressBar progress={min} total={max} />
            </div>
            
        </Hero>
        {questions && <div className="container layoutmargin">
            {questions.map((d,index)=><Question question={d.question} value={d.answer} number={cnt+index+1} onchange={(e)=>{
                d.answer=e;
                setReload(reload+1);
             }} />)}
            
            <div className="my-5 d-flex justify-content-center">
                {min===max?<Button text={"Generating Report"} loading={generating} primary={true} disabled={_.filter(questions,{answer:0}).length>0} onClick={()=>{
                    
                }}></Button>:<Button size="large" text={"Next"} loading={saving} primary={true} disabled={_.filter(questions,{answer:0}).length>0} onClick={()=>{
                    let saveData = questions.map((d)=>({question_id:d.question_id,answer:d.answer}));
                    setSaving(true);
                    API.SaveQuestions(saveData).then(()=>{
                        GetQuestions();
                        setSaving(false);
                    }).catch((err)=>{
                        
                    })
                }}></Button>}
            </div>
        </div>}
        <Footer />
    </>
}

export default Survey;