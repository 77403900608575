import './progressbar.scss'
function ProgressBar({progress,total})
{
    let pecentage = (progress*100)/total;
    return <>
        <div className={'progressbar'}>
          <div>{pecentage.toFixed(0)}%</div>
          <div className={'progress'}>
            <div className={`progress_in ${pecentage===100?'progress_in_success':''}`} style={{width:pecentage.toString()+'%'}}>
              
            </div>
          </div>

        </div>
    </>
}

export default ProgressBar