import baseService from "./baseService";

export function GetUser()
{
    return baseService.get("/getuser.php");
}

export function GetStarted()
{
    return baseService.get("/start.php");
}

export function GetQuestions()
{
    return baseService.get("/getquestions.php");
}

export function SaveQuestions($data)
{
    return baseService.post("/savequestions.php",$data);
}

export function GenerateReport()
{
    return baseService.get("/complete.php");
}

export function GetResult()
{
    return baseService.get("/getresult.php");
}