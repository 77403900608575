import Header from "../Layout/header";
import Button from "../components/Button";
import Hero from "../components/Hero/hero";
import * as API from '../../services/users'
import Footer from "../Layout/footer";
import { useState } from "react";
function Home({onStart})
{
    const [saving,setSaving] = useState(false);
    return <>
        <Header />
        <Hero>
            <h1 className="text-center">Assess Your CEO Strengths in Less Than 10 Minutes</h1>
        </Hero>
        <div className="container layoutmargin">
            <p>This online assessment estimates your leadership performance as well as where you may currently need support. We’ll ask you how much you agree or disagree with 31 statements that align with key concepts in the Chief Executive Operating System (CEO-S). Once complete, we will show you your score and offer some follow-up thoughts.</p>
            <p>But the value doesn’t stop there!</p>
            <p><strong>After completing the assessment, you will have the opportunity for a free 30-minute consultation with CEO-S cofounder Joel Trammell.</strong> Joel will review your strengths as identified by the assessment, as well as discuss what types of tools and support you may want to use for areas that are keeping your organization from peak performance.</p>
            <div className="d-flex justify-content-center mt-5 pt-5">
                <Button loading={saving} text={"GET STARTED"} primary={true} onClick={()=>{
                    setSaving(true);
                    API.GetStarted().then((res)=>{
                        onStart();
                    }).catch((err)=>{
                        setSaving(false);
                    })
                }}></Button>
            </div>
        </div>
        <Footer />
    </>
}

export default Home;