import './Button.scss'
export default function Button({id,title,text,icon,round,primary,secondary,size="medieum",postfix,disabled,onClick,loading,white,fluid}) {

    return (
      <><div>
        <button 
        id={id}
        title={title} 
        disabled={disabled || loading}
        onClick={onClick}
        style={fluid?{width:'100%'}:{}}
        className={`${round?'btn_round':'btn'} ${round?(size==="large"?'btn_round_large':size==="small"?'btn_round_small':''):''} ${size==="large"?'btn_large':size==="small"?'btn_small':''} ${white?'white_back':secondary?'secondary_back':primary?'primary_back':'default_back'} ${primary?'primary_color':secondary?'secondary_color':'default_color'}`}>
            {loading && <img src={"/img/loader.svg"} alt='loading' height={24} width={24} />}
            {!loading && icon}
            <span style={{whiteSpace:'nowrap'}}>{text}</span>
            {postfix}
        </button>
        </div>
      </>
    )
  }
  